<div
  class="prizm-navigation-menu-item"
  [class.prizm-navigation-menu-item_active_node]="isActiveNode"
  [class.prizm-navigation-menu-item_active]="isActive"
  [class.prizm-navigation-menu-item_hovered]="isHovered"
  [class.prizm-navigation-menu-item_expandable]="isExpandable"
  [tabindex]="0"
  [prizmHint]="item.text"
  [prizmHintCanShow]="prizmIsTextOverflow(elem)"
  (keydown.space)="interaction.emit(item)"
  (keydown.enter)="interaction.emit(item)"
  (click)="interaction.emit(item)"
  (prizmHoveredChange)="isHovered = $event"
>
  <prizm-icons-full
    class="prizm-navigation-menu-item__icon"
    *ngIf="item.icon"
    [name]="item.icon"
  ></prizm-icons-full>
  <p class="prizm-navigation-menu-item__text" #elem>
    {{ item.text }}
  </p>
  <div class="prizm-navigation-menu-item__extra">
    <ng-container
      [ngTemplateOutlet]="item.extraTemplate || itemExtraTemplate"
      [ngTemplateOutletContext]="{ $implicit: item, isHovered: isHovered }"
    ></ng-container>
  </div>
  <button
    *ngIf="goToButtonsVisible"
    [size]="'m'"
    [icon]="'arrow-turn-left-up'"
    [appearance]="'secondary'"
    [appearanceType]="'ghost'"
    (click)="$event.stopPropagation(); goToParentItem.emit(item)"
    prizmIconButton
  ></button>
  <button
    *ngIf="goToButtonsVisible"
    [size]="'m'"
    [icon]="'arrow-up-to-dotted-line'"
    [appearance]="'secondary'"
    [appearanceType]="'ghost'"
    (click)="$event.stopPropagation(); goToRootItem.emit(item)"
    prizmIconButton
  ></button>
  <button
    class="expand-button"
    *ngIf="expandButtonVisible"
    [appearance]="'secondary'"
    [appearanceType]="'ghost'"
    [size]="'m'"
    [icon]="isExpanded ? 'chevron-down' : 'chevron-right'"
    [class.t-button_expanded]="isExpanded"
    (click)="$event.stopPropagation(); toggleExpanded.emit(item)"
    prizmIconButton
  ></button>
</div>
