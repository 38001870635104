<button
  class="switcher switcher-text"
  [class.has-value]="prizmHasValue.hasVal$ | async"
  [disabled]="isDisabled"
  [icon]="icon"
  [size]="size"
  (blur)="switcherControlDirective.onTouched()"
  [appearanceType]="appearanceTypeDirective.appearanceType"
  [appearance]="appearanceDirective.appearance"
  [class.switcher_active_disabled]="isActive && isDisabled"
  [pseudoPressed]="isActive"
  type="button"
  prizmButton
>
  <div #prizmHasValue="prizmHasValue" prizmHasValue>
    <ng-content></ng-content>
  </div>
</button>

<ng-container *ngIf="prizmHasValue.empty">
  <button
    class="switcher switcher_icon"
    [pseudoPressed]="isActive"
    [disabled]="isDisabled"
    [icon]="icon"
    [size]="size"
    [appearanceType]="appearanceTypeDirective.appearanceType"
    [appearance]="appearanceDirective.appearance"
    type="button"
    prizmIconButton
  ></button>
</ng-container>
